<template>
    <div class="mt-3">
        <datatable @changeVaule="changeVaule" :stt="true" :columns="columns" :rows="packageEdit" :showPerPage="100">
            <th slot="thead-tr">
                Thao Tác
            </th>
            <template slot="tbody-tr" slot-scope="props">
                <td>
                    <button @click="setPriceUpdate(props.row)" type="button" class="btn bg-orange btn-xs px-3">
                        <i class="fa fa-user-circle-o"></i>
                        Lưu
                    </button>
                </td>
            </template>
        </datatable>
        <div class="text-right mt-3">
            <button @click="setPriceUpdate()" class="mt-md-0 btn bold btn-dark-blue" type="submit">Lưu Bảng Giá</button>
        </div>
    </div>
</template>
<script>
import { priceUpdate } from "../../../api/admin-agency-lv2"
import datatable from "@/components/datatable/datatable.vue"
export default {
    name: "site-price-detail",
    components: {
        datatable
    },
    props: {
        selectedTool: Object,
        tools: Array
    },
    data() {
        return {
            packageEdit: [],
            columns: [
                {
                    label: "Icon",
                    field: "icon",
                    icon: true
                },
                {
                    label: "Tên Tools",
                    field: "name"
                },
                {
                    label: "Giá Site Gốc",
                    field: "priceAdmin",
                    numeric: true
                },
                {
                    label: "Cài Giá Mới",
                    field: "priceEdit",
                    input: true
                },
                {
                    label: "Link Icon",
                    field: "icon",
                    input: true
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        },
        packages() {
            return this.selectedTool && this.selectedTool.prices ? this.selectedTool.prices : []
        },
        packagesAdmin() {
            return this.selectedTool && this.selectedTool.prices_admin ? this.selectedTool.prices_admin : []
        },
        agency() {
            return this.$store.state.agency
        }
    },
    watch: {
        selectedTool(value) {
            let discount =
                this.tools && this.tools[0] && this.tools[0].level_admin ? this.tools[0].level_admin[0].discount : 0
            if (value) {
                this.packageEdit = []
                this.packages.forEach(pack => {
                    let p = pack
                    let pAdmin = this.packagesAdmin.find(item => item.package_name == p.package_name)
                    p.priceEdit = pack.price
                    p.priceAdmin = pAdmin ? pAdmin.price : pAdmin.price - (pAdmin.price * (100 - discount)) / 100
                    this.packageEdit.push(p)
                })
            }
        }
    },
    methods: {
        changeVaule(row, key, value) {
            this.packageEdit = this.packageEdit.map(item => {
                item[key] = item == row ? value : item[key]
                return item
            })
        },
        setPriceUpdate: async function(tool = null) {
            if (tool) {
                let postData = {
                    id: tool.id,
                    price: tool.priceEdit,
                    icon: tool.icon
                }
                let data = await priceUpdate(postData)
                if (data.success && data.status === 200) {
                    this.$toastr.success(data.message)
                    this.$emit("getToolAdmin")
                } else {
                    this.$toastr.error(data.message || data.error)
                }
            } else {
                let successCount = 0
                let duplicate = 0
                let errorText = ""
                if (this.packageEdit.length === 1) {
                    let pack = this.packageEdit[0]
                    if (pack.priceEdit === pack.price) {
                        errorText = "Vui lòng thay đổi giá trước khi tiến hành cập nhật"
                    } else if (Number(pack.priceEdit) < 0) {
                        errorText = "Vui lòng chỉnh giá lớn hơn hoặc bằng 0"
                    } else {
                        let postData = {
                            id: pack.id,
                            price: pack.priceEdit,
                            icon: pack.icon
                        }
                        let data = await priceUpdate(postData)
                        if (data.status === 200 && data.success) {
                            successCount++
                        } else {
                            errorText += data.message + " " + pack.name + "\n"
                        }
                    }
                } else {
                    for (let i = 0; i < this.packageEdit.length; i++) {
                        let pack = this.packageEdit[i]
                        if (pack.price !== pack.priceEdit) {
                            if (Number(pack.priceEdit) < 0) {
                                errorText += "Giá gói: " + pack.name + " không thể < 0 \n"
                            } else {
                                let postData = {
                                    id: pack.id,
                                    price: pack.priceEdit,
                                    icon: pack.icon
                                }
                                let data = await priceUpdate(postData)
                                if (data.status === 200 && data.success) {
                                    successCount++
                                } else {
                                    errorText += data.message + " " + pack.name + "\n"
                                }
                            }
                        } else duplicate++
                    }
                }
                if (duplicate === this.packageEdit.length) {
                    this.$swal("Lỗi", "Vui lòng thay đổi giá trước khi tiến hành cập nhật", "error")
                } else if (successCount === 0) {
                    this.$swal("Lỗi", errorText, "error")
                } else {
                    this.$emit("getToolAdmin")
                    this.$swal(
                        "Thành công",
                        "Đã cập nhật thành công " + successCount + " gói. \n" + errorText,
                        "success"
                    )
                }
            }
        }
    }
}
</script>
