<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="row">
            <div class="col-lg-8 col-12">
                <div class="card card-gray">
                    <div class="card-header bg-dark-blue">
                        <h6 class="bold mb-0 text-center">CÀI ĐẶT BẢNG GIÁ</h6>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-md-3">
                                <h6 class="mb-0 font-bold">Chọn Level:</h6>
                            </div>
                            <div class="col-md-9">
                                <select v-model="chosen_level" class="form-control input-light">
                                    <option v-for="level in levelList" :key="level.level_id" :value="level">
                                        {{ level.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3 align-items-center">
                            <div class="col-md-3">
                                <h6 class="mb-0 font-bold">Chọn Tool:</h6>
                            </div>
                            <div class="col-md">
                                <select v-model="selectedTool" class="form-control input-light">
                                    <option v-for="tool in configTools" :key="tool.id" :value="tool">
                                        {{ tool.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-auto">
                                <span v-if="selectedTool" class="mr-2" v-html="selectedTool.hide_html"></span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 text-right">
                                <span class="bold mr-1">Site gốc</span>
                                <span v-if="selectedTool" class="mr-2" v-html="selectedTool.status_html"></span>
                                <button
                                    v-if="selectedTool && selectedTool.hidden"
                                    @click="toggleTool(selectedTool)"
                                    type="button"
                                    :class="['btn btn-dark-blue']"
                                >
                                    <i class="fa fa-eye"></i>
                                    Click để Bật Tool
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    @click="toggleTool(selectedTool)"
                                    :class="['btn btn-dark-blue']"
                                >
                                    <i class="fa fa-eye-slash"></i>
                                    Click để Ẩn Tool
                                </button>
                            </div>
                        </div>
                        <sitepricesdetail
                            @getToolAdmin="getToolAdmin"
                            :tools="tools"
                            :selectedTool="selectedTool"
                        ></sitepricesdetail>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card card-gray-2 mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6 class="bold">
                                <i class="fa fa-info mr-1"></i>
                                Hướng dẫn
                            </h6>
                            <li>Bạn có thể chỉnh sửa giá, bật/tắt các dịch vụ theo từng level của khách tại đây.</li>
                            <li>Bạn có xem giá từ Site gốc và cài đặt giá cho site hiện tại.</li>
                            <li>Bạn có thể tắt và bật tools.</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getListLevels, getToolAdmin, hideTool } from "../../../api/admin-agency-lv2"
// import { catchError } from "../../../helpers"
import sitepricesdetail from "./site-prices-detail"
import menus from "../config/menu-agency-lv2"
export default {
    name: "site-price",
    components: {
        sitepricesdetail
    },
    data() {
        return {
            menus: menus,
            chosen_level: "",
            quick_update_ratio: "100%",
            levelList: [],
            configTools: [],
            tools: [],
            selectedTool: {},
            chosenTool: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.agency.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        },
        agency() {
            return this.$store.state.agency
        }
    },
    watch: {
        tools: {
            handler: function() {
                this.toolConfigMenu()
                let toolSelect = this.chosenTool && this.chosenTool.id ? this.chosenTool : this.configTools[0]
                let toolCurrent = this.configTools.find(tool => tool.id == toolSelect.id)
                this.selectedTool = toolCurrent
            },
            deep: true
        },
        selectedTool(value) {
            this.chosenTool = value
        },
        chosen_level() {
            this.getToolAdmin()
        },
        reload() {
            this.getToolAdmin()
        }
    },
    async created() {
        await this.getListLevels()
        this.chosen_level = this.levelList.find(level => level.default == 1)
    },
    methods: {
        toggleTool: async function(tool) {
            let data = await hideTool(tool.id)
            if (data.status === 200 && data.success) {
                await this.getToolAdmin()
                this.$toastr.success((tool.not_hidden ? "Đã ẩn: " : "Đã bật: ") + tool.notes)
            } else this.$toastr.erorr("Lỗi, không thể ẩn: " + tool.notes)
        },
        getToolAdmin: async function() {
            if (this.chosen_level) {
                this.tools = await getToolAdmin(this.chosen_level.level_id).then(data => data.data)
            }
        },
        getListLevels: async function() {
            let data = await getListLevels()
            this.levelList = data.data
        },
        toolConfigMenu: function() {
            let tableTool = []
            menus.forEach(item => {
                if (item.tool_alias) {
                    let tool = this.tools.find(tool => {
                        return tool.tool_alias === item.tool_alias
                    })
                    if (tool) {
                        tableTool.push(tool)
                    }
                }
                if (item.childs) {
                    item.childs.forEach(child => {
                        if (child.tool_alias) {
                            let tool = this.tools.find(tool => {
                                return tool.tool_alias === child.tool_alias
                            })
                            if (tool) {
                                tableTool.push(tool)
                            }
                        }
                    })
                }
            })
            this.configTools = this.tools.map((tool, index) => {
                if (tool.notes === "Buff Like, Comment, Share Post") {
                    tool.notes = "Buff Like, Comment, Share Post, Follow, Like Page"
                    tool.name = "Buff Like, Comment, Share Post, Follow, Like Page"
                }
                if (tool.notes === "Tăng Like Bài Viết Instagram") {
                    tool.notes = "Buff Like, Sub, Comment Instagram"
                }
                let price = tool.prices[0] ? tool.prices[0].price : 0
                tool.index = index + 1
                tool.hide_html = tool.hidden
                    ? '<span class="badge badge-danger p-2"><i class="fa fa-eye-slash mr-1"></i>Đang ẩn</span>'
                    : '<span class="badge badge-info p-2"><i class="fa fa-eye mr-1"></i>Đang bật</span>'
                tool.status_html =
                    tool.status === 1
                        ? '<span class="badge badge-success p-2">Đang bật</span>'
                        : tool.status === 0
                        ? '<span class="badge badge-warning p-2" style="min-width: 65px">Bảo trì</span>'
                        : '<span class="badge badge-danger p-2" style="min-width: 65px">Ẩn</span>'
                tool.price_html =
                    '<div class="form-control text-muted text-center" style="background-color: #e9ecef; opacity:0.8; min-width: 120px; display:inline-block">' +
                    price.toLocaleString("it-IT") +
                    " " +
                    this.currency +
                    "</div>"
                return tool
            })
        }
    }
}
</script>
