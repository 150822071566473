const menus = [
    {
        name: "Dịch Vụ Facebook",
        service: "facebook",
        tool_alias: "Facebook Seeding",
        childs: [
            {
                path: "/services/facebook-like",
                name: "Like bài viết Facebook",
                type: "like",
                text: "like",
                tool_alias: "seeding_by_workers_like"
            },
            {
                path: "/services/facebook-follow",
                name: "Theo dõi trang cá nhân",
                type: "follow",
                text: "theo dõi",
                tool_alias: "seeding_by_workers_follow"
            },
            {
                path: "/services/facebook-like-page",
                name: "Like Fanpage",
                type: "like_page",
                text: "like",
                tool_alias: "seeding_by_workers_like_page"
            },
            {
                path: "/services/facebook-review",
                name: "Đánh giá Fanpage",
                type: "review",
                text: "đánh giá",
                tool_alias: "seeding_by_workers_review"
            },
            {
                path: "/services/facebook-comment",
                name: "Tăng bình luận bài viết",
                type: "comment",
                text: "bình luận",
                tool_alias: "seeding_by_workers_comment"
            },
            {
                path: "/services/facebook-share",
                name: "Chia sẻ bài viết Facebook",
                type: "share",
                text: "chia sẻ",
                tool_alias: "seeding_by_workers_share"
            }
        ]
    },
    {
        name: "Dịch Vụ Facebook VIP",
        service: "facebook-vip",
        tool_alias: "Facebook Vip Seeding",
        childs: [
            {
                path: "/services-vip/facebook-clone",
                name: "Vip Like Clone Giá Rẻ",
                text: "like",
                type: "like",
                tool_alias: "vip_seeding_like_clone"
            },
            {
                path: "/services-vip/facebook-like",
                name: "Vip Like Tháng",
                text: "like",
                type: "like",
                tool_alias: "vip_seeding_like"
            },
            {
                path: "/services-vip/facebook-sl",
                name: "Vip Like Số Lượng",
                text: "like",
                type: "like",
                tool_alias: "vip_seeding_like_sl"
            },
            {
                path: "/services-vip/facebook-reaction",
                name: "Vip Like Cảm Xúc",
                text: "like",
                type: "like",
                tool_alias: "vip_seeding_like"
            },
            {
                path: "/services-vip/facebook-comment",
                name: "Vip Bình Luận",
                text: "bình luận",
                type: "comment",
                tool_alias: "vip_seeding_comment"
            }
        ]
    },
    {
        name: "Dịch Vụ Instagram",
        service: "instagram",
        tool_alias: "Instagram Seeding",
        childs: [
            {
                path: "/services/instagram-like",
                name: "Like bài viết Instagram",
                type: "like",
                text: "like",
                tool_alias: "instagram_like"
            },
            {
                path: "/services/instagram-follow",
                name: "Theo dõi cá nhân Instagram",
                type: "follow",
                text: "theo dõi",
                tool_alias: "instagram_follow"
            },
            {
                path: "/services/instagram-comment",
                name: "Bình luận bài viết Instagram",
                type: "comment",
                text: "bình luận",
                tool_alias: "instagram_comment"
            },
            {
                path: "/services-vip/instagram-like",
                name: "Vip Like bài viết Instagram",
                type: "like",
                text: "like",
                tool_alias: "instagram_vip_like"
            }
        ]
    }
    // {
    //     name: "Dịch vụ khác",
    //     service: "other",
    //     tool_alias: "spam_sms",
    //     childs: [
    //         {
    //             path: "/spam-sms",
    //             name: "Auto Spam SMS",
    //             text: "Auto Spam SMS",
    //             tool_alias: "spam_sms",
    //             type: "SMS"
    //         }
    //     ]
    // }
]
export default menus
